<template>
	<div class="examine">
		<div class="wrapper">
            <div class="screen">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>审核列表</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="statistics-screen">
                    <el-button type="primary" v-loading="downloadLoading" @click="handleDownload" class="main-btn">导出未录入列表</el-button>
                </div>
            </div>
			<el-table :data="tableData" class="main-table" header-row-class-name="table-header"
				cell-class-name="table-hover" :row-class-name="tableRowClassName"
				@sort-change="handleSort"
				:default-sort="{prop: 'modifyTime', order: 'descending'}">
				<el-table-column label="序号" type="index" :index="indexMethod" width="80" align="center">
				</el-table-column>
				<el-table-column prop="product.buyCode" label="订货备件代码"></el-table-column>
				<el-table-column prop="product.name" label="备件名称"></el-table-column>
				<el-table-column prop="product.supplierName" label="供应商"></el-table-column>
				<el-table-column prop="product.supplier" label="供应商代码" align="center"></el-table-column>
				<el-table-column prop="product.storeLoc" label="库位号" align="center"></el-table-column>
				<el-table-column prop="modifyTime" label="录入时间" sortable="custom" align="center"></el-table-column>
				<el-table-column prop="user" label="录入人员" align="center"></el-table-column>
				<el-table-column prop="status" label="审核状态" sortable="custom">
					<template slot-scope="scope">
						<el-tag type="success" plain disable-transitions v-if="scope.row.status === 10">通过</el-tag>
						<el-tag type="warning" plain disable-transitions v-if="scope.row.status === -10">不通过</el-tag>
						<el-tag type="warning" plain disable-transitions v-if="scope.row.status === -1">审核超时</el-tag>
						<el-tag disable-transitions v-if="scope.row.status === 2">未审核</el-tag>
                        <el-tag disable-transitions v-if="scope.row.status === 1">未录入</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="120" align="center" class-name="table-operate">
					<template slot-scope="scope">
						<el-button class="operation-btn" size="small" title="审核信息" type="warning"
							@click="editClick(scope.row)">审核信息</el-button>
						<!-- <el-button type="danger" icon="el-icon-delete" circle size="small" title="删除"
							@click="deleteClick(scope.row)"></el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="total, prev, pager, next" :total="this.total" :current-page.sync="query.page"
					:page-size="query.pagesize" @current-change="getList">
				</el-pagination>
		</div>
	</div>
</template>

<script>
	import http from "../../http.js";
    import { fetchProductNoImgList } from "@/api/product";

	export default {
		name: "examine",
		components: {},
		data() {
			return {
                downloadLoading: false,
				tableData: [],
				query: {
                    page: 1,
                    pagesize: 10,
					sort: '-modify_time',
                    status: 2
				},
                total: ''
			};
		},
		created() {
			this.getList();
            document.addEventListener('visibilitychange', this.handleVisiable)
        },
        destroyed() {
            document.removeEventListener('visibilitychange', this.handleVisiable)
        },
        methods: {
            handleDownload() {
                this.downloadLoading = true
                Promise.all([fetchProductNoImgList()]).then(resArr => {
                    let list = resArr[0].data
                    import('@/vendor/Export2Excel').then(excel => {
                        const tHeader = ['备品代码', '备品名称']
                        const filterVal = ['buyCode', 'name']
                        const data = this.formatJson(filterVal, list)
                        excel.export_json_to_excel({
                            header: tHeader,
                            data,
                            filename: "备品未录入列表",
                            autoWidth: false,
                            bookType: 'xlsx'
                        })
                        this.downloadLoading = false
                    })
                })
            },
            formatJson(filterVal, jsonData) {
                return jsonData.map(v => filterVal.map(j => {
                    if (j === 'state' && v[j]) {
                        return this.stateFormat({
                            state: v[j]
                        })
                    } else {
                        return v[j]
                    }
                }))
            },
            handleVisiable(e){
                if (e.target.visibilityState === 'visible') {
                    this.getList()
                }
            },
            indexMethod(e){
                console.log(e)
                return (this.query.page - 1)*this.query.pagesize + e + 1
            },
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
			getList() {
				http.fetchGet("/api/admin/product/modify/list", this.query).then((res) => {
					if (res.data.code == 0) {
						this.tableData = res.data.data.list;
                        this.total = res.data.data.total
					}
				});
			},
			editClick(item) {
                const detail = this.$router.resolve({name: 'sparesDetails', query: {id: item.id}})
                window.open(detail.href,'_blank')
			},
			deleteClick() {
				this.$confirm("确定要永久删除该行备品吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$message({
							type: "success",
							message: "删除成功!",
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
			},
			handleSort(e) {
                let field = e.prop
                if(field == 'modifyTime'){
                    field = 'modify_time'
                }
				console.log(e);
				if (e.order == "ascending") {
					this.query.sort = "+" + field
				} else if (e.order == "descending") {
					this.query.sort = "-" + field
				} else {
					this.query.sort = ""
				}
				// this.query.page = 1
				this.getList()
			},
		},
	};
</script>

<style lang="scss">
	@import '~@/assets/css/examine.scss';
</style>

